import { ClockCircleOutlined } from '@ant-design/icons'
import useMediaQuery from '@cannect/hooks/useMediaQuery'
import { MOBILE_WIDTH } from '@cannect/utils/constants'
import LoadingContainer from 'components/LoadingContainer'
import { AnimatePresence, motion } from 'framer-motion'
import { Order, PaymentMethod } from 'hooks/useCartCheckout'
import { usePrepareCart } from 'hooks/usePrepareCart'
import useQueryString from 'hooks/useQueryString'
import { CheckCircle } from 'lucide-react'
import { useCallback, useEffect, useState } from 'react'
import { RiErrorWarningFill } from 'react-icons/ri'
import { useHistory, useLocation } from 'react-router-dom'
import api from 'services/api'
import StatusModal from '../StatusModal'
import { CountDown } from './CountDown'
import * as Style from './styles'

interface Props {
  order?: Order
  payment?: PaymentMethod
  pixInfo?: string
  loadingContainer: boolean
  isCardPix?: boolean
  pixAmount?: number
}

interface PixTransaction {
  qr_code: string
  qr_code_url: string
  expires_at: string
  charge_id: string
  order_code: string
}

interface ChargeStatus {
  success: boolean
  order_paid: boolean
  order: {
    id: number
    uuid: string
    amount: string
    originCheckout: string
  }
  charge: {
    status: string
  }
}

export function Pix({ loadingContainer, isCardPix = false, pixAmount = 0 }: Props) {
  const [lineIsCopied, setLineIsCopied] = useState(false)
  const [error, setError] = useState(false)
  const isMobile = useMediaQuery(MOBILE_WIDTH)

  const [, setCopyClipboard] = useState<string>('')
  const [pixTransaction, setPixTransaction] = useState<PixTransaction | null>(null)
  const [isConfirmed, setIsConfirmed] = useState(false)
  const [isExpired, setIsExpired] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isProcessingPayment, setIsProcessingPayment] = useState(false)
  const [paymentStatus, setPaymentStatus] = useState<string>('pending')
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [errorDetails, setErrorDetails] = useState<{
    status?: string
    acquirer_message?: string
  } | null>(null)
  const { lastOrderCreated, setOrderId, clearOrderData } = usePrepareCart()
  const location = useLocation()

  // Extract order ID from URL path
  const orderIdFromUrl = location.pathname.split('/').pop()

  const { filters } = useQueryString()
  const cart_id = filters?.cart_id
  const history = useHistory()

  const isAllSupplements = lastOrderCreated?.items.every(
    (item: any) => item?.product?.class_id === 6 && item.product?.brand_id === 108
  )
  const originCheck = ['acolhimento-cannect', 'B2C', 'COD B', 'Recipe', 'COD C']

  const copyByClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text)
    setCopyClipboard(text)
    setLineIsCopied(true)
  }

  const checkPaymentStatus = useCallback(
    async (orderId: string, chargeId: string) => {
      try {
        const response = await api.get<ChargeStatus>(`payments/charge/${orderId}/${chargeId}`)
        if (response.data.success) {
          setPaymentStatus(response.data.charge.status)
          if (response.data.charge.status === 'paid') {
            setPaymentSuccess(true)
            localStorage.removeItem('@CANNECT:ORDER_CHECKOUT_CART_ID')
            setOrderId(null)
            clearOrderData()
            // Wait 3 seconds before redirecting

            setTimeout(() => {
              history.push(`/analise-cannect-wa?cart_id=${response.data.order.uuid}`)
            }, 3000)
          }
        }
      } catch (error) {
        console.error('Error checking payment status:', error)
      }
    },
    [history]
  )

  useEffect(() => {
    let intervalId: NodeJS.Timeout

    if (isConfirmed && pixTransaction) {
      intervalId = setInterval(() => {
        const orderId = pixTransaction.order_code || orderIdFromUrl
        checkPaymentStatus(orderId, pixTransaction.charge_id)
      }, 5000)
    }

    return () => {
      if (intervalId) clearInterval(intervalId)
    }
  }, [isConfirmed, pixTransaction, checkPaymentStatus, orderIdFromUrl])

  const handleFinishOrder = async () => {
    try {
      setIsLoading(true)
      setIsProcessingPayment(true)
      const payload = {
        order_id: lastOrderCreated.id,
        payments: [
          {
            paymentMethod: { type: 'pix' },
            amount: Math.round(lastOrderCreated.amount * 100)
          }
        ]
      }

      const response = await api.post('/payments/charges', payload)
      if (response.data.success && response.data.responses && response.data.responses.length > 0) {
        const paymentResponse = response.data.responses[0]
        if (paymentResponse.last_transaction && !paymentResponse.last_transaction.success) {
          setErrorDetails({
            status: 'Erro ao gerar PIX',
            acquirer_message: 'Erro ao processar pagamento'
          })
          throw new Error('Payment transaction was not successful')
        } else if (paymentResponse.last_transaction && paymentResponse.last_transaction.success) {
          const transaction = paymentResponse.last_transaction
          const chargeId = paymentResponse.id
          const orderCode = paymentResponse.code
          setPixTransaction({
            qr_code: transaction.qr_code,
            qr_code_url: transaction.qr_code_url,
            expires_at: transaction.expires_at,
            charge_id: chargeId,
            order_code: orderCode
          })
          setIsConfirmed(true)
          setIsExpired(false)

          const documents = response.data?.documents

          const documentList = documents?.reduce(
            (acc, doc) => {
              acc[doc.type] = doc
              return acc
            },
            {} as Record<string, any>
          )

          if (!isAllSupplements) {
            const validateAnvisa =
              (documentList?.ANVISA?.length ?? 0) > 0 || (documentList?.PROCURACAO?.length ?? 0) > 0

            if (originCheck.some((origin) => origin === lastOrderCreated?.originCheckout) && !validateAnvisa) {
              const validator = await api.get(`anvisaAuth/order/${lastOrderCreated.id}`)

              if (validator?.data?.success && validator?.data?.message === 'waitingDocs') {
                const response = await api.post('anvisaAuth/launchAnvisa', {
                  order_id: lastOrderCreated.id
                })
              } else if (validator?.data?.success && validator?.data?.message === 'inexist') {
                const response = await api.post('anvisaAuth/clickAutomation', {
                  order_id: lastOrderCreated.id
                })
              }
            }
          }
        } else {
          throw new Error('Error processing PIX payment')
        }
      } else {
        setErrorDetails({
          status: 'Erro ao gerar PIX',
          acquirer_message: 'Erro ao processar pagamento'
        })
        throw new Error('Error processing PIX payment')
      }
    } catch (error) {
      console.error('Error processing PIX payment:', error)
      setError(true)
      setIsProcessingPayment(false)
    } finally {
      setIsLoading(false)
    }
  }

  const handleExpiration = () => {
    setIsExpired(true)
    setIsProcessingPayment(false)
  }

  if (loadingContainer) {
    return <LoadingContainer loading />
  }

  if (isCardPix) {
    return (
      <Style.Container isMobile={isMobile}>
        <Style.ConfirmationCard>
          <Style.CardHeader>
            <h2>Pagamento Parcial via PIX</h2>
          </Style.CardHeader>
          <Style.CardContent>
            <Style.OrderDetails>
              <div className="detail-row">
                <span>Valor a pagar via PIX:</span>
                <strong>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(pixAmount)}
                </strong>
              </div>
            </Style.OrderDetails>
            <Style.InfoText>
              <p>
                Após preencher os dados do cartão de crédito, clique em "Finalizar pedido" para gerar o QR Code PIX.
              </p>
              <p>O pagamento via PIX deve ser realizado em até 30 minutos após a geração do QR Code.</p>
            </Style.InfoText>
          </Style.CardContent>
        </Style.ConfirmationCard>
        <StatusModal
          onClickButton={() => {
            setError(false)
            setErrorDetails(null)
          }}
          variant="error"
          icon={<RiErrorWarningFill />}
          textButton="Tentar Novamente"
          isOpen={error}
          onClose={() => {
            setError(false)
            setErrorDetails(null)
          }}
          title="Houve um erro ao processar seu pagamento"
          text={
            errorDetails ? (
              <div className="error-group">
                <h4>Erro no PIX</h4>
                <div className="detail-item">
                  <span className="label">Status:</span>
                  <span className="value">{errorDetails.status}</span>
                </div>
                <div className="detail-item">
                  <span className="label">Mensagem:</span>
                  <span className="value">{errorDetails.acquirer_message}</span>
                </div>
              </div>
            ) : (
              'Por favor, revise seus dados e tente novamente'
            )
          }
        />
      </Style.Container>
    )
  }

  if (!isConfirmed) {
    return (
      <Style.Container isMobile={isMobile}>
        <Style.ConfirmationCard>
          <Style.CardHeader>
            <h2>Confirmação de Pagamento via PIX</h2>
          </Style.CardHeader>
          <Style.CardContent>
            <Style.OrderDetails>
              <div className="detail-row">
                <span>Valor total:</span>
                <strong>
                  {new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL'
                  }).format(lastOrderCreated?.amount || 0)}
                </strong>
              </div>
              <div className="detail-row">
                <span>Método de pagamento:</span>
                <strong>PIX</strong>
              </div>
              <div className="detail-row">
                <span>Tempo de expiração:</span>
                <strong>30 minutos</strong>
              </div>
            </Style.OrderDetails>
            <Style.InfoText>
              <p>Ao confirmar, você receberá um QR Code PIX para realizar o pagamento.</p>
              <p>O pagamento deve ser realizado em até 30 minutos para garantir a confirmação do seu pedido.</p>
            </Style.InfoText>
            <Style.ButtonContainer>
              <Style.Button onClick={handleFinishOrder} disabled={isLoading}>
                {isLoading ? 'Gerando QR Code...' : 'Gerar QR Code PIX'}
              </Style.Button>
            </Style.ButtonContainer>
          </Style.CardContent>
        </Style.ConfirmationCard>
        <StatusModal
          onClickButton={() => {
            setError(false)
            setErrorDetails(null)
          }}
          variant="error"
          icon={<RiErrorWarningFill />}
          textButton="Tentar Novamente"
          isOpen={error}
          onClose={() => {
            setError(false)
            setErrorDetails(null)
          }}
          title="Houve um erro ao processar seu pagamento"
          text={
            errorDetails ? (
              <div className="error-group">
                <h4>Erro no PIX</h4>
                <div className="detail-item">
                  <span className="label">Status:</span>
                  <span className="value">{errorDetails.status}</span>
                </div>
                <div className="detail-item">
                  <span className="label">Mensagem:</span>
                  <span className="value">{errorDetails.acquirer_message}</span>
                </div>
              </div>
            ) : (
              'Por favor, revise seus dados e tente novamente'
            )
          }
        />
      </Style.Container>
    )
  }

  return (
    <Style.Container isMobile={isMobile}>
      <Style.PixCard>
        <Style.CardHeader>
          <h2>Pagamento via PIX</h2>
        </Style.CardHeader>
        <Style.CardContent>
          {isConfirmed && pixTransaction ? (
            <AnimatePresence>
              {paymentSuccess ? (
                <Style.SuccessMessage
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}>
                  <motion.div animate={{ rotate: 360 }} transition={{ duration: 0.6 }}>
                    <CheckCircle size={48} className="success-icon" />
                  </motion.div>
                  <h3>Pagamento confirmado!</h3>
                  <p>Você será redirecionado em instantes...</p>
                </Style.SuccessMessage>
              ) : (
                <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                  <Style.CountDownContainer>
                    <ClockCircleOutlined style={{ fontSize: '24px', paddingRight: '10px' }} />
                    <Style.CountDownContent>
                      <CountDown expiresAt={pixTransaction.expires_at} onExpire={handleExpiration} />
                      <p>para expirar</p>
                    </Style.CountDownContent>
                  </Style.CountDownContainer>
                  <Style.QrCodeContainer>
                    <img src={pixTransaction.qr_code_url} alt="QR Code PIX" />
                    <Style.QrCodeinfos>
                      <p>1. Abra o app do seu banco ou instituição financeira.</p>
                      <p>2. Escolha a opção de pagamento via PIX.</p>
                      <p>3. Escaneie o QR Code ou copie e cole o código PIX.</p>
                      <p>4. Confirme as informações e finalize o pagamento.</p>
                      <p>5. Após a confirmação, você será redirecionado para a página de resumo do pedido.</p>
                    </Style.QrCodeinfos>
                  </Style.QrCodeContainer>
                  {!lineIsCopied ? (
                    <Style.ButtonCopy onClick={() => copyByClipboard(pixTransaction.qr_code)}>
                      Copiar código PIX
                    </Style.ButtonCopy>
                  ) : (
                    <Style.ButtonCopied>Código PIX copiado</Style.ButtonCopied>
                  )}
                  <Style.PaymentStatus>
                    Status do pagamento:{' '}
                    {paymentStatus === 'pending' ? 'Aguardando pagamento' : 'Processando pagamento'}
                  </Style.PaymentStatus>
                </motion.div>
              )}
            </AnimatePresence>
          ) : (
            <Style.ExpiredMessage>
              <p>O tempo para pagamento expirou. Por favor, gere um novo QR Code PIX.</p>
              <Style.Button onClick={handleFinishOrder} disabled={isLoading}>
                {isLoading ? 'Gerando QR Code...' : 'Gerar novo QR Code PIX'}
              </Style.Button>
            </Style.ExpiredMessage>
          )}
        </Style.CardContent>
      </Style.PixCard>
      <StatusModal
        onClickButton={() => {
          setError(false)
          setErrorDetails(null)
        }}
        variant="error"
        icon={<RiErrorWarningFill />}
        textButton="Tentar Novamente"
        isOpen={error}
        onClose={() => {
          setError(false)
          setErrorDetails(null)
        }}
        title="Houve um erro ao processar seu pagamento"
        text={
          errorDetails ? (
            <div className="error-group">
              <h4>Erro no PIX</h4>
              <div className="detail-item">
                <span className="label">Status:</span>
                <span className="value">{errorDetails.status}</span>
              </div>
              <div className="detail-item">
                <span className="label">Mensagem:</span>
                <span className="value">{errorDetails.acquirer_message}</span>
              </div>
            </div>
          ) : (
            'Por favor, revise seus dados e tente novamente'
          )
        }
      />
    </Style.Container>
  )
}
